import * as React from "react";
import {Props as SwipeListProps, SwipeList} from "./SwipeList";
import {arrayChunks} from "../../../../utils/array-utils";

interface Props<T> {
    data: T[];
    // The number of elements on one page
    numOnPage: number;
    // How to render an item `el` at the position `pos` in the list.
    renderItem: (el: T, pos?: number) => React.ReactNode;
    swipeListProps?: SwipeListProps;
}

/**
 * A swipe list list chunking data according to the number of elements on one page.
 *
 * The content of each page is wrapped inside a row, so the renderItem callback should take this into account and
 * can add the col classes to the rendered element if needed.
 */
export class ChunkingSwipeList<T> extends React.Component<Props<T>> {
    private readonly pages: T[][] = arrayChunks(this.props.data, this.props.numOnPage);

    public static defaultProps: Partial<Props<{}>> = {swipeListProps: {}};

    public render() {
        return (
            <SwipeList {...this.props.swipeListProps}>
                {this.pages.map(this.renderPage)}
            </SwipeList>
        );
    }

    private renderPage = (page: T[], pageIndex: number) => (
        <div key={pageIndex} className="row">
            {page.map((el, index) => this.props.renderItem(el, pageIndex * this.props.numOnPage + index))}
        </div>
    );
}