import * as React from "react";
import {getText} from "../../../utils/static-utils";
import {LangSC} from "../../../utils/lang";
import {AllHTMLAttributes} from "react";
import {Text} from "./Text";

export interface Props<T extends keyof LangSC> extends AllHTMLAttributes<HTMLDivElement> {
    page: T;
    entry: keyof LangSC[T];
}

/**
 * A plain or rich text, according to the translation object contained in the given page and given entry in page.
 */
export const StaticText = <T extends keyof LangSC>({page, entry, ...props}: Props<T>) => <Text{...props} text={getText(page, entry)}/>;