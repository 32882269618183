import * as React from "react";
import {getSharedText} from "../../utils/static-utils";
import {Text} from "../utils/components/Text";

export class Footer extends React.Component {
    public render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="content">
                        {/*<div className="apps">
                            <p>{getSharedText("footer_mobile_app")}</p>
                            <div className="app-badges">
                                <a className="google-play" href="https://play.google.com/store/apps/details?id=ch.heigvd.iict.showroomhealth">
                                    <img src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
                                </a>
                                <a className="app-store" href="https://itunes.apple.com/ne/app/showroom-health/id644205203">
                                    <img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"/>
                                </a>
                            </div>
                        </div>*/}
                    <Text className="impressum" text={getSharedText("footer_impressum")}/>
                    </div>
                </div>
            </div>
        );
    }
}