import * as React from "react";
import {BoundSection} from "../home/BoundSection";
import {FullPageType} from "../../utils/lang";
import {StaticText} from "../utils/components/StaticText";
import {Route} from "../../utils/route-utils";

export interface Props {
    page: FullPageType;
}

export class Intro extends React.Component<Props> {
    public render() {
        return (
            <BoundSection id="intro" resume="hello">
                <div className="video-wrapper">
                    <video loop muted autoPlay>
                        <source src={Route.ASSETS + "/video/intro.mp4"} type="video/mp4"/>
                    </video>
                    <div className="layer"/>
                </div>
                <div className="intro-text-wrapper">
                    <div className="intro-text">
                        <StaticText className="title" page={this.props.page} entry={"intro_title"}/>
                        <StaticText className="description" page={this.props.page} entry={"intro_description"}/>
                    </div>
                </div>
            </BoundSection>
        );
    }
}