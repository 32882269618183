import * as React from "react";

export interface Props {
    onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
}

/**
 * A clickable content that exposes a `onClick` callback, usable both for `onClick` and simulates an `onTouch` as well.
 *
 * It keeps track of whether the mouse / touch has moved from the start of the click / touch and calls the `onTouch`
 * callback only if there wasn't any movement (thus, a click / touch has been performed).
 *
 * Useful in a scrollable list where the drag event is implemented using the mousedown / mousemove / mouseup combination
 * (or the respective touche events) and we still want to have an action performed on the element when a click / tap was
 * done on it but not if it was grabbed, for example a link in a card (must open a tab but when card dragged, does nothing).
 */
export class ClickableContent extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
        onClick: () => {},
    };

    private readonly mouseContext = {moved: false, down: false, downPos: {x: 0, y: 0}};

    public render() {
        return (
            <div
                onMouseDown={this.handleTouchStart}
                onMouseMove={this.handleTouchMove}
                onMouseUp={this.handleTouchEnd}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
            >
                {this.props.children}
            </div>
        );
    }

    private handleTouchStart = (e: React.MouseEvent | React.TouchEvent) => {
        this.mouseContext.moved = false;
        this.mouseContext.down = true;
        this.mouseContext.downPos = {x: getPageX(e), y: getPageY(e)};
    };

    private handleTouchMove = (e: React.MouseEvent | React.TouchEvent) => {
        if (!this.mouseContext.down) {
            return;
        }

        const dx = this.mouseContext.downPos.x - getPageX(e);
        const dy = this.mouseContext.downPos.y - getPageY(e);

        // if mouse moved move than 3 px, consider it has moved
        if (Math.sqrt(dx * dx + dy * dy) > 3) {
            this.mouseContext.moved = true;
            this.mouseContext.down = true;
        }
    };

    private handleTouchEnd = (e: React.TouchEvent | React.MouseEvent) => {
        // do nothing if the touch comes from outside the element / the touch moved, thus no need to call onTouch
        if (!this.mouseContext.down || this.mouseContext.moved) {
            return;
        }

        this.mouseContext.down = false;
        this.mouseContext.moved = false;

        this.props.onClick(e);
    };
}

// Shorthands to get offset to left of the page using an event
const getPageX = (event) => event.pageX || (event.touches && event.touches[0] && event.touches[0].pageX) || 0;
const getPageY = (event) => event.pageY || (event.touches && event.touches[0] && event.touches[0].pageY) || 0;