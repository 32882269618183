import * as React from "react";
import {AllHTMLAttributes} from "react";
import {getTranslation} from "../../utils/lang";
import {SkillData} from "../../utils/directus-types/skills";
import {getPicture} from "../../utils/media";
import {Text} from "../utils/components/Text";

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    skill: SkillData;
    useDescription?: boolean;
    imageOutlined?: boolean;
}

export const Skill = ({skill, useDescription, imageOutlined, ...props}: Props) => {
    const image = getPicture(skill.medias);
    const translation = getTranslation(skill.translations);

    return (
        <div key={skill.id} {...props} className={"skill " + (props.className || "")}>
            {image && <img src={(imageOutlined ? toOutline(image) : image)}/>}
            {translation && translation.name && <Text className={"skill-name"} text={translation.name}/>}
            {useDescription && translation && translation.description && <Text className={"skill-description"} text={translation.description}/>}
        </div>
    );
};

const toOutline = (fileName: string) => {
    const lastIndex = fileName.lastIndexOf(".");
    return fileName.substr(0, lastIndex) + "-ob" + fileName.substr(lastIndex);
};
