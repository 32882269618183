import * as React from "react";
import {formatDate} from "../../../utils/date-utils";
import {LearnMore} from "./buttons/ButtonMore";
import {Text} from "./Text";
import {ClickableContent} from "./ClickableContent";

export interface Props {
    image?: string;
    title?: string;
    description?: string;
    date?: {
        value: string;
        // Which color of bubble to use for the card. If not set, the other display (orange label) is used to contain the date.
        bubble?: "orange" | "blue";
        // Whether to include the day in the date displayed on the card. Only useful when the date is in a label.
        includeDay?: boolean;
    }
    // The address of the link.
    link?: string;
    // Whether the content of the card is wrapped in a link instead of a div (useful when not in a swipelist)
    asLink?: boolean;
    // What to do when the card is clicked.
    onClick: (event: React.MouseEvent | React.TouchEvent) => void;
}

/**
 * A card for a project or an activity.
 *
 * Can be used in swipe lists or as it is, but it is recommended to set `asLink` to true when not in a swipelist.
 * It is because when in a swipelist, it is needed to detect when the mouse is pressed and see if the mouse moved to
 * trigger a drag event, and in that case not trigger the onClick event.
 *
 * If the `asLink` is false, the behavior is to do such detections on click to determine whether the click event should be called.
 */
export class Card extends React.PureComponent<Props> {
    public render() {
        const props = this.props;
        const content = (
            <div className="card">
                <div
                    className="img-wrapper"
                    style={
                        props.image ? {
                            background: `#c4c4c4 url("${props.image}") center no-repeat`,
                            backgroundSize: "cover"
                        } : {}
                    }
                >
                    {this.renderDate()}
                </div>
                <div className="card-content">
                    {props.title && <h2>{props.title}</h2>}
                    {props.description && <Text text={props.description} keepFirstParagraph/>}
                    {props.link && <LearnMore className="inline" href={props.link} onClick={this.handleClick} useSpan={this.props.asLink}/>}
                </div>
            </div>
        );

        return (
            this.props.asLink ? (
                <a href={this.props.link} onClick={this.handleClick}>
                    {content}
                </a>
            ) : (
                this.props.onClick ? (
                    <ClickableContent onClick={this.props.onClick}>
                        {content}
                    </ClickableContent>
                ) : content
            )
        );
    }

    private renderDate = () => {
        const date = this.props.date;
        if (!date || !date.value) {
            return null;
        }
        if (date.bubble) {
            return (
                <div className={"date bubble " + date.bubble}>
                    <p>{formatDate(date.value, {day: "numeric", month: "short"})}</p>
                </div>
            );
        } else {
            const includeDay = date.includeDay == null ? true : date.includeDay;
            return (
                <div className={"date label"}>
                    <p>{formatDate(date.value,  {...(includeDay ? {day: "2-digit"} : {}), month: "long", year: "numeric"})}</p>
                </div>
            );
        }
    };

    /**
     * Handles the click on the card.
     *
     * When the card is a link (`asLink` is true), the behavior on "normal" click is to call the `onClick` from the parent
     * but if cmd or ctrl is pressed, opens it in a new tab (which is the default behavior for a link in that case).
     *
     * Note that this behavior doesn't impede the SEO since on the rendered page, the link in the card is still visible
     * on the rendered DOM.
     * @param e - The click event.
     */
    private handleClick = (e: React.MouseEvent) => {
        /*
         * If the card is wrapped in a link, we can treat the card as a link and simply need to detect if the ctrl / cmd
         * or middle click is used to let the default behavior kick in (open link in new tab). Else, we simply
         * call the onClick of the parent, probably to open a modal.
         *
         * If the card is not a link, we simply want to prevent the default behavior of the click on it in order to call the
         * onClick from the parent.
         */
        if (this.props.asLink) {
            // "normal" click -> call parent onClick
            if (!e.ctrlKey && !e.metaKey) {
                e.preventDefault();
                this.props.onClick(e);
            }
        } else {
            e.preventDefault();
        }
    };
}