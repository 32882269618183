import * as React from "react";
import {PageType} from "../../utils/directus-types/static-page";
import {Lang} from "../../utils/lang";
import {Path} from "../../utils/path";

export interface Props {
    activePage?: PageType;
}

export class ChangeLang extends React.Component<Props> {
    public render() {
        const match = Path.current.match(/^\/[^\/]+(\/.+)$/);
        const suffix = match && match.length > 0 ? match[1] : "";
        return (
            <div className="change-lang">
                <div>
                    <a className={Lang.lang === "fr" ? "active" : ""} href={"/fr" + suffix}>FR</a>
                </div>
                <div>
                    <a className={Lang.lang === "en" ? "active" : ""} href={"/en" + suffix}>EN</a>
                </div>
                {/*
                <div className="col-6 search">
                    <button>Icon</button>
                </div>
                */}
            </div>
        );
    }
}