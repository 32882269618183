import {Lang} from "./lang";

/**
 * The base of the different types of routes available.
 */
export enum Route {
    HOME = "/",
    PLATFORM = "/platform",
    PROJECTS = "/projects",
    TEACHING = "/teaching",
    ACTIVITIES = "/activities",
    ASSETS = "/assets",
    WELL_KNOWN = "/.well-known",
    NOT_FOUND = "/404",
    NEWS = "/news",
    EVENTS = "/events",
    ERROR = "/500",
    FAVICON = "/favicon.ico",
    ROBOTS = "/robots.txt",
    PRIVACY = "/privacy",
}

/**
 * Allows building an url on the same site according to the given path and the current language used.
 * @param path - The path to use.
 */
export const buildUrl = (path: string) => `/${Lang.lang}${path}`;

/**
 * Builds an url overview, for more readable links (i.e the "test" par in project/id/test).
 * @param text - The text to use.
 */
export const buildOverview = (text: string) => (text && text.substring(0, 50)) || "";
