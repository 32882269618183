import * as React from "react";
import {StaticImages} from "../../static-images";

export interface Props {
    className?: string;
    // What to do when the close button is pressed (which appears only if `fullPage` is false)
    onClose: () => void;
    // Whether the close button is inside the container. If false, at the root of the modal.
    closeButtonInside?: boolean;
    // The color of the close button.
    closeButtonColor?: "white" | "blue";
    // Whether to hide the close button.
    hideCloseButton?: boolean;
    // Whether to close the modal when clicking outside of content.
    closeOutside?: boolean;
    // Whether to close the modal when clicking inside of content.
    // If true, the various elements of the container MUST stop the click propagation, else the modal will close.
    closeInside?: boolean;
    // Whether the content is wrapped inside a container.
    withContainer?: boolean;
}

/**
 * A modal component with a close button.
 */
export class Modal extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
        onClose: () => {},
        closeButtonInside: true,
        hideCloseButton: false,
        closeOutside: true,
        closeInside: false,
        closeButtonColor: "white",
        withContainer: true,
    };

    public render() {
        const closeButton = (
            <img
                className={"close-modal-button" + (this.props.hideCloseButton ? " hidden " : " ") + this.props.closeButtonColor}
                src={StaticImages.closeButton(this.props.closeButtonColor)}
                onClick={this.props.onClose}
            />
        );

        const content = (
            <div className="content" onClick={this.handleContentClick}>
                {this.props.closeButtonInside && closeButton}
                {this.props.children}
            </div>
        );

        return (
            <div className={"modal " + (this.props.className || "")} onClick={this.handleModalClick}>
                {!this.props.closeButtonInside && closeButton}
                {this.props.withContainer ? (
                    <div className="container">
                        {content}
                    </div>
                ) : content}
            </div>
        )
    }

    private handleContentClick = (e: React.MouseEvent) => {
        if (this.props.closeInside) {
            this.props.onClose();
        } else {
            e.stopPropagation();
        }
    };

    private handleModalClick = () => {
        if (this.props.closeOutside) {
            this.props.onClose();
        }
    };
}