import * as React from "react";

export interface Props {
    useDots?: boolean; // Whether to display navigation dots below the list.
    pageWidth?: number; // The width of the page. By default, pageWidth = container width.
    onPageChange?: (index: number) => void; // What to do when a page changes.
    onRef?: (ref: SwipeList) => void; // Allows using the ref of the element to call methods on it.
}

interface State {
    index: number;
}

export class SwipeList extends React.Component<Props, State> {
    public static defaultProps: Partial<Props> = {
        pageWidth: 0,
        onPageChange: () => {},
        onRef: () => {},
    };

    private readonly numPages = React.Children.count(this.props.children);
    private readonly maxIndex = this.numPages - 1;
    private moveContext = {
        // Whether the mouse click is down
        mouseDown: false,
        // Whether the mouse has moved
        mouseMoved: false,
        // The position (relative to the LEFT OF WINDOW) where the mouse was down.
        // Not relative to container because it is easier to compute distance moved when comparing with event.pageX.
        downPos: 0,
        // Where the mouse is IN THE CONTAINER (not relative to window)
        curPos: 0
    };

    private container: HTMLDivElement;
    private content: HTMLDivElement;

    public readonly state: State = {index: 0};

    public componentDidMount() {
        this.props.onRef(this);
        document.documentElement.addEventListener("mouseup", this.handleMouseUp as any);
    }

    public componentWillUnmount() {
        this.props.onRef(null);
        document.documentElement.removeEventListener("mouseup", this.handleMouseUp as any);
    }

    render() {
        return (
            <div className="swipe-list" onMouseUp={this.handleMouseUp} onTouchEnd={this.handleMouseUp}>
                {this.renderButton(this.state.index <= 0, this.decreaseIndex, true)}
                <div
                    ref={this.handleContentRef}
                    className="content"
                    onMouseDown={this.handleMouseDown}
                    onTouchStart={this.handleMouseDown}
                    onMouseMove={this.handleMouseMove}
                    onTouchMove={this.handleMouseMove}
                >
                    <div ref={this.handleContainerRef} className="page-container">
                        {this.props.children}
                        {/* needed because outer margins are not shown in overflow scroll/hidden */}
                        <div className="spacing-right"/>
                    </div>
                </div>
                {this.renderButton(this.state.index >= this.maxIndex, this.increaseIndex, false)}
                {this.renderDots()}
           </div>
        );
    }

    private renderButton = (disabled: boolean, onClick: () => void, left: boolean) => (
        this.props.useDots ? null : (
            <button
                className={`button-${left ? "left" : "right"}`}
                disabled={disabled}
                onClick={onClick}
                onTouchStart={onClick}
            >
                <i className={`fas fa-chevron-${left ? "left" : "right"}`}/>
            </button>
        )
    );

    private renderDots = () => (
        this.props.useDots ? (
            <div className="dots">
                {Array(this.numPages).fill(0).map((_, index) => (
                    <div
                        key={index}
                        className={"dot" + (index === this.state.index ? " active" : "")}
                        onClick={this.handleDotClick(index)}
                        onTouchStart={this.handleDotClick(index)}
                    />
                ))}
            </div>
        ) : null
    );

    private handleContainerRef = (ref: HTMLDivElement) => this.container = ref;
    private handleContentRef = (ref: HTMLDivElement) => this.content = ref;
    private handleDotClick = (index: number) => () => this.updatePosition(index);

    // Updates the index of the current page and moves the content accordingly.
    private updatePosition = (curIndex: number) => {
        this.setState(
            {index: curIndex < 0 ? 0 : curIndex >= this.maxIndex ? this.maxIndex : curIndex},
            () => {
                this.container.style.transform =
                    `translateX(${-(this.state.index * (this.props.pageWidth ||this.container.offsetWidth))}px)`;
                this.props.onPageChange(this.state.index);
            }
        );
    };

    private decreaseIndex = () => this.updatePosition(this.state.index - 1);
    private increaseIndex = () => this.updatePosition(this.state.index + 1);

    private handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        this.moveContext.mouseDown = true;
        this.moveContext.mouseMoved = false;
        this.moveContext.downPos = getPageX(e);
        this.content.classList.add("grabbing");
    };

    private handleMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
        if (!this.moveContext.mouseDown) {
            return;
        }
        this.moveContext.mouseMoved = true;
        const width = this.props.pageWidth || this.container.offsetWidth;

        const containerLeft = this.state.index * width;
        const pos = containerLeft - (getPageX(e) - this.moveContext.downPos);
        const moveLimit = width / 3;

        // move to new position or limit movement if on the leftmost / rightmost side
        this.moveContext.curPos =
            this.state.index >= this.maxIndex && pos > containerLeft + moveLimit ? containerLeft + moveLimit :
                this.state.index <= 0 && pos < containerLeft - moveLimit ? containerLeft - moveLimit : pos;
        this.container.style.transform = `translateX(${-(this.moveContext.curPos)}px)`;
    };

    private handleMouseUp = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        const width = this.props.pageWidth || this.container.offsetWidth;

        // Check in case mouse down was not done outside // mouse moved
        if (this.moveContext.mouseDown && this.moveContext.mouseMoved) {

            // change the index on the nearest page, using the left of a page as a landmark
            const lastPos = this.state.index * width;
            const changeLimit = width / 4;
            const diff = this.moveContext.curPos - lastPos;

            // update page accordingly
            if (diff > changeLimit) {
                this.increaseIndex();
            } else if (diff < -changeLimit) {
                this.decreaseIndex();
            } else {
                this.updatePosition(this.state.index);
            }
        }

        this.moveContext.mouseDown = false;
        this.content.classList.remove("grabbing");
    };

    /**
     * Moves the list to the given page.
     * @param index - The index of the page.
     */
    public setPage = (index: number) => this.updatePosition(index);
}

// Shorthand to get offset to left of the page using an event
const getPageX = (event) => event.pageX || (event.touches && event.touches[0] && event.touches[0].pageX) || 0;