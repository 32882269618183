import * as React from "react";

interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
    onRef?: (ref: HTMLDivElement) => void; // optional reference on the div wrapper
}

/**
 * Represents a section in a page.
 * Adds an invisible anchor a bit earlier than the section itself to allow jumping to the section while allowing the
 * section to be a bit below the navbar and not directly underneath.
 */
export class Section extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
        onRef: () => {},
    };

    public render() {
        const {onRef, id, className, ...props} = this.props;

        return (
            <div ref={onRef} id={id} className={"section " + (className || "")} {...props}>
                <div id={id + "-anchor"} className="section-anchor"/>
                {this.props.children}
            </div>
        );
    }
}