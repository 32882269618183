import * as React from "react";
import {ButtonLink, Props as ButtonProps} from "./buttons/ButtonLink";
import {Text} from "./Text";

interface Props {
    className?: string;
    title?: string;
    image?: {
        location: string; // Where the image is
        link: string; // The link where the <a> of the image should point to
        belowText?: boolean; // Whether the image is below the text
    }
    content: string;
    button?: ButtonProps;
    buttonComponent?: React.ReactNode;
}

export class TextBlock extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
        className: "",
    };

    public render() {
        const {className, title, content, button, image} = this.props;
        const img = image ? (
            <div className="img-wrapper">
                <a href={image.link} target="_blank">
                    <img src={image.location}/>
                </a>
            </div>
        ) : null;

        return (
            <div className={"text-block " + className}>
                {title && <h1>{title}</h1>}
                {image && !image.belowText && img}
                <Text text={content}/>
                {image && image.belowText && img}
                {this.props.button && <ButtonLink {...button}/>}
                {this.props.buttonComponent && this.props.buttonComponent}
            </div>
        );
    }
}