import * as React from "react";
import {TextBlock} from "../utils/components/TextBlock";
import {buildUrl, Route} from "../../utils/route-utils";
import {Card} from "../utils/components/Card";
import {getHomeText} from "../../utils/static-utils";
import {LearnMore} from "../utils/components/buttons/ButtonMore";
import {ProjectData} from "../../utils/directus-types/projects";
import {getTranslation} from "../../utils/lang";
import {getBanner} from "../../utils/media";
import {Text} from "../utils/components/Text";
import {SwipeList} from "../utils/components/lists/SwipeList";
import {ClickableContent} from "../utils/components/ClickableContent";
import {SkillData} from "../../utils/directus-types/skills";

interface Props {
    projects: ProjectData[];
    skills: SkillData[];
}

interface State {
    activeIndex: number;
}

interface Data {
    id: number;
    title: string;
    description: string;
    summary: string;
    image: string;
    link: string;
}

export class Projects extends React.Component<Props, State> {
    public readonly state: State = {activeIndex: 0};

    // pre-computed project data with corresponding translation & medias
    private projectData: Data[] = this.props.projects.map(p => {
        const translation = getTranslation(p.translations);
        const skills = p.competences || [];
        // == instead of === because allow matching number/strings
        const matchingFirstSkill = this.props.skills.filter(s => s.id == (skills[0] || {competence_id: 0}).competence_id);
        const firstSkill = matchingFirstSkill.length > 0 ? matchingFirstSkill[0] : undefined;
        const media = getBanner(p.medias) || getBanner(firstSkill && firstSkill.medias);

        return {
            id: p.id,
            title: translation && translation.title,
            description: translation && translation.description,
            summary: translation && translation.summary,
            image: media,
            link: `${buildUrl(Route.PROJECTS)}/${p.id}/${translation.title ? translation.title.substr(0, 50) : ""}`,
        };
    });

    public render() {
        return (
            <div className="projects">
                <h1>{getHomeText("projects_title")}</h1>
                <div className="row">
                    <TextBlock
                        className="col-12 col-md-6 description"
                        content={getHomeText("projects_description")}
                        button={{
                            text: getHomeText("button_all_projects"),
                            href: buildUrl(Route.PROJECTS),
                            newTab: false
                        }}
                    />
                    <div className="col-12 col-md-6 col-lg-6 project-overview">
                        {this.renderHCardList()}
                        {this.renderVCardList()}
                    </div>
                </div>
            </div>
        );
    }

    private renderVCardList = () => {
        return (
            <div className="d-block d-xl-none">
                <SwipeList>
                    {this.projectData.map(data => (
                        <Card
                            key={data.id}
                            title={data.title}
                            description={data.summary}
                            image={data.image}
                            link={data.link}
                            onClick={this.handleCardClick(data.link)}
                        />
                    ))}
                </SwipeList>
            </div>
        );
    };

    private handleCardClick = (link: string) => () => window.open(link);

    private renderHCardList = () => {
        return (
            <div className="d-none d-xl-block">
                <SwipeList useDots>
                    {this.projectData.map(this.renderHCard)}
                </SwipeList>
            </div>
        );
    };

    private renderHCard = (data: Data) => {
        return (
            <ClickableContent key={data.id} onClick={this.handleCardClick(data.link)}>
                <div className="row">
                    <div
                        className="col-5 project-img"
                        style={{
                            background: `#c4c4c4 url("${data.image}") center no-repeat`,
                            backgroundSize: "cover"
                        }}
                    />

                    <div className="col-7 project-description">
                        <h2>{data.title}</h2>
                        <Text text={data.summary}/>
                        <LearnMore className="inline" href={data.link} onClick={this.handleCardClick(data.link)}/>
                    </div>
                </div>
            </ClickableContent>
        );
    };
}
