import * as React from "react";
import {InstitutionData} from "../../../../utils/directus-types/institutions";
import {Partner} from "../../../shared/Partner";
import {ChunkingSwipeList} from "./ChunkingSwipeList";

interface Props {
    partners: InstitutionData[];
    numOnPage: number;
    useDots?: boolean;
}

/**
 * A list of partners.
 */
export class PartnerList extends React.Component<Props> {
    private slProps = {useDots: this.props.useDots};

    public render() {
        return (
            <ChunkingSwipeList
                data={this.props.partners}
                numOnPage={this.props.numOnPage}
                swipeListProps={this.slProps}
                renderItem={this.renderPartner}
            />
        );
    }

    private renderPartner = (partner: InstitutionData, index: number) => <Partner key={index} {...partner}/>;
}