import * as React from "react";
import {Navbar} from "./Navbar";
import {Footer} from "./Footer";
import {Intro} from "./Intro";
import {FullPageType} from "../../utils/lang";

export interface Props {
    activePage: FullPageType;
}

/**
 * The base component used by all full pages (that have intro, navbar, footer, etc).
 *
 * Note that the page given as props should have the "intro" field in the static content for the given page.
 * The static content is fetched on the server-side and transformed to be put in the Lang.sc object (which is then
 * transmitted in an object contained in the resulting markup to the client).
 */
export class Base extends React.Component<Props> {
    public render() {
        const page = this.props.activePage;
        return (
            <>
                <Navbar activePage={page}/>
                <Intro page={page}/>
                <div className="content">
                    {this.props.children}
                </div>
                <Footer/>
            </>
        );
    }
}