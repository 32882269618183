import * as ReactDOM from "react-dom";
import * as React from "react";
import "babel-polyfill";
import "whatwg-fetch";
import {Lang} from "../../utils/lang";
import {Path} from "../../utils/path";
import "nouislider/distribute/nouislider.min.css";
import * as smoothscroll from 'smoothscroll-polyfill';
import "@fortawesome/fontawesome-free/css/all.css"

// Polyfills scroll options for window.scroll* functions
smoothscroll.polyfill();

/**
 * The initialisation function to be called to recreate the React tree on the client.
 * If the __PRELOADED_STATE__ variable is set in the returned DOM, it will interpret it as a state that was prepared
 * by the SSR to be passed to the component as props.
 *
 * @param Component - The component to use as the root of the tree.
 */
export const init = (Component: React.ComponentType) => {
    type W = Window & {__PRELOADED_STATE__: object};

    // Grab the state that was injected into the server-generated HTML
    const state: any = (window as any as W).__PRELOADED_STATE__ || {};

    if (state.lang) {
        Lang.lang = state.lang.lang;
        Lang.sc = state.lang.sc;
        Lang.mapping = state.lang.mapping;
    }

    Path.current = window.location.pathname + window.location.search;

    ReactDOM.hydrate(
        <Component {...state}/>,
        document.getElementById("root"),
    );

    // Allow the passed state to be garbage-collected
    delete (window as any as W).__PRELOADED_STATE__;
};
