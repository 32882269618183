import * as React from "react";
import {Base} from "../shared/Base";
import {Presentation} from "./Presentation";
import {Skills} from "./Skills";
import {Partners} from "./Partners";
import {Location} from "./Location";
import {SectionNav} from "./SectionNav";
import {Activities} from "./Activities";
import {Projects} from "./Projects";
import {Teaching} from "./Teaching";
import {BoundSection} from "./BoundSection";
import {SkillData} from "../../utils/directus-types/skills";
import {ProjectData} from "../../utils/directus-types/projects";
import {InstitutionData} from "../../utils/directus-types/institutions";
import {EventData, NewsData} from "../../utils/directus-types/activities";

interface Props {
    skills: SkillData[];
    projects: ProjectData[];
    partners: InstitutionData[];
    news: NewsData[];
    events: EventData[];
}

export class Home extends React.Component<Props> {
    public render() {
        return (
            <>
                <SectionNav/>
                <Base activePage="home">
                    <Presentation/>
                    <Skills skills={this.props.skills}/>
                    <BoundSection id="projects-teaching" resume="how" className="container">
                        <Projects projects={this.props.projects} skills={this.props.skills}/>
                        <Teaching/>
                    </BoundSection>
                    <Activities news={this.props.news} events={this.props.events}/>
                    <Location/>
                    <Partners partners={this.props.partners}/>
                </Base>
            </>
        );
    }
}