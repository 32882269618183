import * as React from "react";
import {Sections} from "../../utils/sections";
import {Section} from "../shared/Section";

/**
 * Represents a section in a page, bound to the side nav on the home page.
 * Saves the ref of the section array to of references on sections to allow the side nav to know in which section the user is.
 */
export class BoundSection extends React.Component<React.AllHTMLAttributes<HTMLDivElement> & {id: string, resume: string}> {
    private sectionRef: HTMLDivElement;

    public componentDidMount() {
        // add the ref for the section navbar
        Sections.sectionInfo.push({id: this.props.id + "-anchor", ref: this.sectionRef, resume: this.props.resume});
    }

    public render() {
        const {resume, ...props} = this.props;
        return (
            <Section onRef={this.handleRef} {...props}>
                {this.props.children}
            </Section>
        );
    }

    private handleRef = (ref: HTMLDivElement) => this.sectionRef = ref;
}