import * as React from "react";
import {buildUrl, Route} from "../../utils/route-utils";
import {getHomeText, getText} from "../../utils/static-utils";
import {StaticText} from "../utils/components/StaticText";
import {LearnMore} from "../utils/components/buttons/ButtonMore";
import {StaticImages} from "../utils/static-images";

type TabType = "bachelor" | "master" | "continuous";

interface State {
    currentTab: TabType;
}

export class Teaching extends React.Component<{}, State> {
    public readonly state: State = {currentTab: "bachelor"};

    public render() {
        const title = getHomeText("teaching_title");
        return (
            <div className="teaching">
                <div className="row">
                    <h1 className="col d-block d-lg-none">{title}</h1>

                    <div className="col-12 col-lg-6 teaching-left">
                        <img src={StaticImages.home.teaching}/>
                        <div className="formations">
                            <div className="row formation-tabs">
                                {this.renderTab("bachelor", "Bachelor")}
                                {this.renderTab("master", "Master")}
                                {this.renderTab("continuous", getText("shared", "continuous_learning"))}
                            </div>
                            <div className="tab-content">
                                {this.state.currentTab === "bachelor" && <StaticText className="bachelor" page="home" entry="bachelor_description"/>}
                                {this.state.currentTab === "master" && <StaticText className="master" page="home" entry="master_description"/>}
                                {this.state.currentTab === "continuous" && <StaticText className="continuous" page="home" entry="continuous_learning_description"/>}
                                <LearnMore
                                    className="inline"
                                    href={buildUrl(`${Route.TEACHING}#${this.state.currentTab}-anchor`)}
                                    newTab={false}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 teaching-right">
                        <h1 className="d-none d-lg-block">{title}</h1>
                        <StaticText className="teaching-description" page={"home"} entry={"teaching_description"}/>
                    </div>
                </div>
            </div>
        );
    }

    private renderTab = (tab: TabType, text: string) => (
        <div
            className={"col-4 tab" + (this.state.currentTab === tab ? " active" : "")}
            onClick={this.handleClick(tab)}
        >
            <p>{text}</p>
        </div>
    );

    private handleClick = (tab: TabType) => () => this.setState({currentTab: tab});
}
