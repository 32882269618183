import {DEFAULT_LANG} from "../config";
import {SCTranslation} from "./directus-types/static-page";
import {TitleDescTranslation, Translation} from "./directus-types/general";
import {STranslation} from "./servlet-types/general";

/**
 * The different types of lang existing.
 */
export type LangType = "en" | "fr";

/**
 * The different locales supported.
 */
export type Locale = "en_US" | "fr_FR";

/**
 * The mapping of the language types and their corresponding locale.
 */
export const LOCALES: {[key in LangType]: Locale} = {
    en: "en_US",
    fr: "fr_FR",
};


/**
 * The static content available for all the pages, grouped by label.
 */
export interface PageSC {
    page_title?: SCTranslation;
}

/**
 * A page with an intro that has a title and a description.
 */
export interface IntroPageSC extends PageSC {
    intro_title?: SCTranslation;
    intro_description?: SCTranslation;
}

/**
 * The static content available for the home page, grouped by label.
 */
export interface HomeSC extends IntroPageSC {
    platform_presentation_title?: SCTranslation;
    platform_presentation_description?: SCTranslation;

    presentation_platform_title?: SCTranslation;
    presentation_platform_description?: SCTranslation;

    presentation_vision_title?: SCTranslation;
    presentation_vision_description?: SCTranslation;

    presentation_mission_title?: SCTranslation;
    presentation_mission_description?: SCTranslation;

    competences_title?: SCTranslation;

    projects_title?: SCTranslation;
    projects_description?: SCTranslation;
    button_all_projects?: SCTranslation;

    teaching_title?: SCTranslation;
    teaching_description?: SCTranslation;
    bachelor_description?: SCTranslation;
    master_description?: SCTranslation;
    continuous_learning_description?: SCTranslation;

    activities_title?: SCTranslation;
    activities_description?: SCTranslation;
    button_all_activities?: SCTranslation;

    address_heig?: SCTranslation;
    contact_heig?: SCTranslation;
    address_hesav?: SCTranslation;
    contact_hesav?: SCTranslation;

    partners_title?: SCTranslation;
    partners_description?: SCTranslation;
}

/**
 * The static content available for the platform page, grouped by label.
 */
export interface PlatformSC extends IntroPageSC {
    actors_title?: SCTranslation;
    actors_heig_description?: SCTranslation;
    actors_hesav_description?: SCTranslation;

    heig_description?: SCTranslation;
    hesav_description?: SCTranslation;

    skills_title?: SCTranslation;
    skills_description?: SCTranslation;

    partners_title?: SCTranslation;
    partners_description?: SCTranslation;
    public_partners?: SCTranslation;
    industrial_partners?: SCTranslation;
    academic_partners?: SCTranslation;
    network_partners?: SCTranslation;
}

/**
 * The static content available for the projects page, grouped by label.
 */
export interface ProjectsSC extends IntroPageSC {
    projects_title?: SCTranslation;
    projects_description?: SCTranslation;
    project_status?: SCTranslation

    search_title?: SCTranslation;
    search_dates?: SCTranslation;
    search_institutions?: SCTranslation;
    search_institutions_select?: SCTranslation;
    search_containing?: SCTranslation;
    search_skills?: SCTranslation;

    button_search?: SCTranslation;
    button_reset?: SCTranslation;

    projects_found?: SCTranslation;
    result_page?: SCTranslation;
    searching?: SCTranslation;
    no_project_found?: SCTranslation;
    loading_project_data?: SCTranslation;
}

/**
 * The static content available for the project detail page, grouped by label.
 */
export interface ProjectsDetailSC extends PageSC {
}

/**
 * The static content available for the teaching page, grouped by label.
 */
export interface TeachingSC extends IntroPageSC {
    curriculum_title?: SCTranslation;
    formations_title?: SCTranslation;
    bachelor_description?: SCTranslation;
    master_description?: SCTranslation;
    continuous_description?: SCTranslation;
}

/**
 * The static content available for the activities page, grouped by label.
 */
export interface ActivitiesSC extends IntroPageSC {
    news_events_title?: SCTranslation;
    news_events_description?: SCTranslation;
}

/**
 * The static content available for the news page, grouped by label.
 */
export interface NewsSC extends PageSC {
}

/**
 * The static content available for the news detail page, grouped by label.
 */
export interface NewsDetailSC extends PageSC {
}

/**
 * The static content available for the event page, grouped by label.
 */
export interface EventsSC extends PageSC {
}

/**
 * The static content available for an event detail page, grouped by label.
 */
export interface EventDetailSC extends PageSC {
}

/**
 * The static content available for the contact page, grouped by label.
 */
export interface ContactSC extends PageSC {
}

/**
 * The static content shared between all the pages (navbar, footer).
 *
 * These fields are not in PageSC since we want to access these data without needing to know in which page we are currently.
 * Indeed, when on home page, the data are put in sc.home, but in navbar we don't know that. It is thus simpler to have
 * a separate sc.shared object that contains all the shared data.
 */
export interface SharedSC {
    navbar_home_title?: SCTranslation;
    navbar_platform_title?: SCTranslation;
    navbar_projects_title?: SCTranslation;
    navbar_teaching_title?: SCTranslation;
    navbar_activities_title?: SCTranslation;
    navbar_contact_title?: SCTranslation;
    navbar_contact_link?: SCTranslation;

    button_learn_more?: SCTranslation;
    button_to_website?: SCTranslation;
    button_see_more?: SCTranslation;

    continuous_learning?: SCTranslation;

    project_managers?: SCTranslation;
    project_team?: SCTranslation;
    project_main_institutions?: SCTranslation;
    project_partner_institutions?: SCTranslation;
    project_link?: SCTranslation;
    project_pdf?: SCTranslation;

    footer_mobile_app?: SCTranslation;
    footer_impressum?: SCTranslation;

    loading_data?: SCTranslation;
}

/**
 * The static content for each page.
 */
export interface LangSC {
    home: HomeSC;
    platform: PlatformSC;
    projects: ProjectsSC;
    project_detail: ProjectsDetailSC;
    teaching: TeachingSC;
    activities: ActivitiesSC;
    news: ActivitiesSC;
    news_detail: ActivitiesSC;
    events: ActivitiesSC;
    event_detail: ActivitiesSC;
    shared: SharedSC;
}

/**
 * A page that is a full page (for example projects page is, but project-detail is not).
 */
export type FullPageType = keyof Pick<LangSC, "home" | "platform" | "projects" | "teaching" | "activities">;

/**
 * An utility namespace used to keep track of the selected language and static content across the different contexts.
 */
export namespace Lang {
    // The mapping lang type -> id type used in the DB.
    export let mapping: {[key: string]: number} = {};

    // The language to use for the page.
    export let lang: LangType = DEFAULT_LANG;

    // The static content available, grouped by page.
    export let sc: LangSC = {
        home: {},
        platform: {},
        projects: {},
        project_detail: {},
        teaching: {},
        activities: {},
        news: {},
        news_detail: {},
        events: {},
        event_detail: {},
        shared: {},
    };
}

/**
 * Get the translation for the current language from a Directus translations object.
 * @param translations - The translations to use.
 * @param defaultValue - The default value to return if the translation object is not usable.
 */
export const getTranslation = <T extends Translation>(translations: T[], defaultValue: T = null): T => {
    // Returns a placeholder if the translation object is not usable
    if (!translations || translations.length === 0) {
        return defaultValue;
    }

    // Used if a translation isn't available for the current language
    let defaultTranslation = null;

    for (let i = 0; i < translations.length; ++i) {
        const code = translations[i].language_code;

        if (code === Lang.mapping[Lang.lang]) {
            return translations[i];
        }

        if (code === Lang.mapping[DEFAULT_LANG]) {
            defaultTranslation = translations[i];
        }
    }

    return defaultTranslation || translations[0];
};

/**
 * Get the translation for the current language from a Servlet translations object.
 * @param translations - The translations to use.
 * @param defaultValue - The default value to return if the translation object is not usable.
 */
export const getSTranslation = <T extends STranslation>(translations: T[], defaultValue: T = null): T => {
    // Returns a placeholder if the translation object is not usable
    if (!translations || translations.length === 0) {
        return defaultValue;
    }

    // Used if a translation isn't available for the current language
    let defaultTranslation = null;

    for (let i = 0; i < translations.length; ++i) {
        const code = translations[i].language.code;

        if (code === Lang.lang) {
            return translations[i];
        }

        if (code === DEFAULT_LANG) {
            defaultTranslation = translations[i];
        }
    }

    return defaultTranslation || translations[0];
};

export const getTitDescTranslation = (translations: TitleDescTranslation[]) =>
    getTranslation(translations, {id: 0, language_code: 0, description: "", title: ""});

/**
 * Gets the locale according to the language used.
 */
export const getLocale = () => {
    switch (Lang.lang) {
        case "fr":
            return "fr-FR";
        case "en":
            return "en-US";
        default:
            return "fr-FR";
    }
};
