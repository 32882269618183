import * as React from "react";
import {Sections} from "../../utils/sections";

interface State {
    activeIndex: number;
    resume: string;
}

/**
 * A navbar between sections on the home page, with clickable dots to go on the corresponding section.
 */
export class SectionNav extends React.Component {
    public readonly state: State = {activeIndex: 0, resume: ""};

    public render() {
        const activeInfo = Sections.sectionInfo[this.state.activeIndex];
        return (
            <div className="section-nav d-none d-lg-block">
                <div className="dots">
                    {Array(Sections.sectionInfo.length).fill(0).map((_, index) => (
                        <a
                            key={index}
                            className={"dot" + (index === this.state.activeIndex ? " active" : "")}
                            onClick={this.handleDotClick(index)}
                        />
                    ))}
                </div>
                <p>{activeInfo ? activeInfo.resume : ""}</p>
            </div>
        );
    }

    public componentDidMount() {
        document.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    }

    private handleDotClick = (index) => () => {
        const element = document.getElementById(Sections.sectionInfo[index].id);
        window.scrollTo({
            top: element.getBoundingClientRect().top + window.pageYOffset,
            behavior: "smooth"
        });
    };

    /**
     * On scroll, check which element is currently viewed by the user and updates the section nav accordingly.
     */
    private handleScroll = () => {
        let activeIndex = 0;
        Sections.sectionInfo.forEach((info, index) => {
            // if element at top of viewport (navbar has an height of 88px but we take a bit more earlier: 30% of viewport height above it)
            if (info.ref.getBoundingClientRect().top < 88 + window.innerHeight * 0.3) {
                activeIndex = index;
            }

            // take the last section if at the end of page (with 3px of margin to see it a bit earlier)
            if (window.pageYOffset + window.innerHeight + 3 >= document.documentElement.scrollHeight) {
                activeIndex = Sections.sectionInfo.length - 1;
            }
        });
        this.setState({activeIndex})
    }
}
