import {Route} from "../../utils/route-utils";

const prefix = Route.ASSETS + "/images/";
const placeholders = prefix + "placeholders/";
const logos = prefix + "logos/";
const ui = prefix + "ui/";

/**
 * The static images.
 */
export class StaticImages {
    public static readonly logos = {
        heig: logos + "heig.svg",
        hesav: logos + "hesav.svg",
        ingesan: logos + "ingsan.svg",
        ingesanBg: logos + "ingsan-bg.svg",
        ingesanNav: logos + "ingsan-nav.svg",
        ingesanNavMob: logos + "ingsan-nav-mob.svg",
    };

    public static readonly home = {
        teaching: prefix + "teaching.jpg",
        map: prefix + "map.png"
    };

    public static readonly platform = {
        mission: prefix + "presentation-mission.png",
        platform: prefix + "presentation-platform.png",
        vision: prefix + "presentation-vision.png",
    };

    public static readonly placeholders = {
        event: placeholders + "event.jpeg",
        news: placeholders + "news.jpeg",
    };

    public static closeButton = (color: "blue" | "white" | "dark") => ui + `close-button-${color}.svg`;

    public static readonly ui = {
        closeButton: {
            blue: StaticImages.closeButton("blue"),
            white: StaticImages.closeButton("white"),
            dark: StaticImages.closeButton("dark"),
        },
        link: ui + "link.png",
    };
};
