import * as React from "react";

export interface Props {
    text: string;
    href: string;
    icon?: string;
    className?: string;
    newTab?: boolean; // Whether to open the link in a new tab.
    useSpan?: boolean; // Whether to use a span instead of a <a>. Useful if the parent is already wrapped in a <a>.
    onClick?: (event: React.MouseEvent) => void;
}

export class ButtonLink extends React.PureComponent<Props> {
    public static defaultProps: Partial<Props> = {
        onClick: () => {},
    };

    private readonly commonProps = {
        onClick: this.props.onClick,
        className: "link"
    };

    public render() {
        const {text, href, icon, className, newTab, useSpan} = this.props;
        const content = (
            <>
                {text}&nbsp;
                {icon && <span className="icon">{icon}</span>}
            </>
        );

        return (
            <div className={"button-link " + (className || "")}>
                {useSpan ? <span {...this.commonProps}>{content}</span> : (
                    <a href={href} target={newTab === false ? "" : "_blank"} {...this.commonProps} draggable={false}>
                        {content}
                    </a>
                )}
            </div>
        );
    }
}