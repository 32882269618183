import * as React from "react";
import {BoundSection} from "./BoundSection";
import {getHomeText} from "../../utils/static-utils";
import {LearnMore} from "../utils/components/buttons/ButtonMore";
import {SkillData} from "../../utils/directus-types/skills";
import {buildUrl, Route} from "../../utils/route-utils";
import {Skill} from "../shared/Skill";
import {Modal} from "../utils/components/detail/Modal";

interface Props {
    skills: SkillData[];
}

interface State {
    selected: number | null; // The index of the selected skill. If no skill is selected, the value is null.
}

export class Skills extends React.Component<Props, State> {
    public readonly state: State = {selected: null};

    public render() {
        return (
            <BoundSection id="skills" resume="what" className="bg-blue-orange">
                <div className="container">
                    <h1>{getHomeText("competences_title")}</h1>
                    <div className="row">
                        {this.props.skills.map(this.renderSkill)}
                    </div>
                    <LearnMore className={"white"} href={buildUrl(Route.PLATFORM + "#skills-anchor")} newTab={false}/>
                </div>
                {this.state.selected !== null && this.renderSkillModal()}
            </BoundSection>
        );
    }

    private renderSkill = (skill: SkillData, index: number) => {
        return (
            <Skill
                key={skill.id}
                skill={skill}
                className="col-6 col-md-3 skill-overview"
                onClick={this.handleClick(index)}
            />
        );
    };

    private handleClick = (index: number) => () => this.setState({selected: index});

    private renderSkillModal = () => (
        <Modal className="skill-modal" onClose={this.handleClose} withContainer={false}>
            <Skill
                skill={this.props.skills[this.state.selected]}
                useDescription
            />
        </Modal>
    );

    private handleClose = () => this.setState({selected: null});
}
