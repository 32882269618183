import {MediaData, MediaType} from "./directus-types/media";
import {SERVER_BASE} from "../config";

/**
 * Returns all medias matching the given type in a Directus media array.
 *
 * @param medias - The media object to use.
 * @param type - The type of the media. Must match; event if there is a media but the type isn't correct, won't be taken into account.
 * @return The links of the media if it exists and is a picture, else an empty array.
 */
const getMedias = (medias: MediaData[], type: MediaType): string[] =>
    medias && medias.length > 0 ? medias.filter(m => m.type === type).map(el => SERVER_BASE + el.link) : [];

/**
 * Gets the last media from a Directus media array that is of the given type.
 *
 * The link of the last of that type is taken, allowing us to simply add a new media without deleting the previous of that type.
 *
 * @param medias - The media object to use.
 * @param type - The type of the media. Must match; even if there is a media but the type isn't correct, won't be taken into account.
 * @return The link of the media if it exists and is of the wanted type, else an empty string.
 */
const getMedia = (medias: MediaData[], type: MediaType): string => {
    const mediasFound = getMedias(medias, type);
    return (mediasFound.length === 0) ? "" : mediasFound[mediasFound.length - 1];
};

// Shorthands
export const getPicture = (medias: MediaData[]) => getMedia(medias, "PICTURE");
export const getPictures = (medias: MediaData[]) => getMedias(medias, "PICTURE");
export const getLogo = (medias: MediaData[]) => getMedia(medias, "LOGO");
export const getBanner = (medias: MediaData[]) => getMedia(medias, "BANNER");
export const getPDFs = (medias: MediaData[]) => getMedias(medias, "PDF");

/** Allows getting the thumbnail url for an image. Only creates the url, that the thumbnail itself may not exist. */
export const toThumbnailUrl = (url: string) => {
    const lastIndex = url.lastIndexOf(".");
    return url.substr(0, lastIndex) + "-thumb" + url.substr(lastIndex);
};

/** Shorthand to create the style for the background image property for a thumbnail. */
export const getBgImageThumbnail = (url: string) => `${url ? `url(${toThumbnailUrl(url)}),` : ""} url(${url})`
