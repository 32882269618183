import {getLocale} from "./lang";

// Removes trailing hours, otherwise date is invalid (chrome supports it in its Date constructor, but not every browser does)
/**
 * Sanitizes a date, removing spaces (Directus dates) and T (servlet dates) separating the year/month/day and the hour/min/sec.
 * @param date - The date to sanitize.
 */
export const sanitizeDate = (date: string) => date.split(" ")[0].split("T")[0];

/**
 * Formats an ISO date (or pseudo-ISO, i.e Directus date) as string to the given locale.
 * @param value - The ISO date to transform.
 * @param options - the format to use (see Date.toLocaleString options)
 * @returns The formatted date as string or an empty string is date invalid.
 */
export const formatDate = (value: string, options): string => {
    if (!value) {
        return "";
    }

    const date = new Date(sanitizeDate(value));

    if (isNaN(date as any as number)) {
        return "";
    }

    return date.toLocaleString(getLocale(), options);
};
