import * as React from "react";
import {BoundSection} from "./BoundSection";
import {getHomeText} from "../../utils/static-utils";
import {Text} from "../utils/components/Text";
import {StaticImages} from "../utils/static-images";

type SelectionType = "heig" | "hesav";

interface State {
    selected: SelectionType | null; // The selected school, null if none is selected.
}

export class Location extends React.Component<{}, State> {
    public readonly state: State = {selected: null};
    private readonly schools = {
        heig: {
            address: getHomeText("address_heig"),
            contact: getHomeText("contact_heig")
        },
        hesav: {
            address: getHomeText("address_hesav"),
            contact: getHomeText("contact_hesav")
        }
    };

    public render() {
        const base = (
            <>
                <img className="map" src={StaticImages.home.map}/>
                {this.renderPin("heig")}
                {this.renderPin("hesav")}
                {this.renderLocNames()}
            </>
        );

        return (
            <BoundSection id="location" resume="where">
                <div className="container wrapper d-none d-md-block">
                    {this.renderInfo(false)}
                    {base}
                </div>
                <div className="d-block wrapper d-md-none">
                    {this.renderInfo(true)}
                    {base}
                </div>
            </BoundSection>
        );
    }

    private renderInfo = (modal: boolean) => {
        if (!this.state.selected) {
            return null;
        }

        const school = this.state.selected === "heig" ? this.schools.heig : this.schools.hesav;
        const modalOpen = modal && !!this.state.selected;
        const content = (
            <div className={"info-wrapper" + (modal ? " info-modal": "")}>
                <div className="info">
                    {modalOpen && (
                        <img
                            className="close-button"
                            src={StaticImages.ui.closeButton.dark}
                            onClick={this.handleCloseClick}
                        />
                    )}
                    <div className="img-wrapper">
                        <img src={this.state.selected === "heig" ? StaticImages.logos.heig : StaticImages.logos.hesav}/>
                    </div>
                    <Text className="address" text={school.address}/>
                    <Text className="contact" text={school.contact}/>
                </div>
            </div>
        );

        if (modalOpen) {
            return (
                <div className="overlay">
                    {content}
                </div>
            );
        }

        return content;
    };

    private renderPin = (type: SelectionType) => (
        <div className={"pin " + type} onClick={this.handlePinClick(type)}>
            <div className="pin-loc">
                <div className="pin-body-wrapper">
                    <div className={"pin-body " + type + (this.state.selected === type ? " active" : "")}>+</div>
                </div>
            </div>
        </div>
    );

    private handlePinClick = (type: SelectionType) => () => this.setState({selected: type});
    private handleCloseClick = () => this.setState({selected: null});

    private renderLocNames = () => (
        <>
            <p className="loc-name yverdon">Yverdon-les-Bains</p>
            <p className="loc-name lausanne">Lausanne</p>
            <p className="loc-name geneve">Genève</p>
        </>
    );
}