import * as React from "react";
import {BoundSection} from "./BoundSection";
import {getHomeText} from "../../utils/static-utils";
import {StaticText} from "../utils/components/StaticText";
import {LearnMore} from "../utils/components/buttons/ButtonMore";
import {buildUrl, Route} from "../../utils/route-utils";
import {InstitutionData} from "../../utils/directus-types/institutions";
import {PartnerList} from "../utils/components/lists/PartnerList";

interface Props {
    partners: InstitutionData[];
}

export class Partners extends React.Component<Props> {
    public render() {
        return (
            <BoundSection id="partners" resume="with" className="container">
                <div className="header">
                    <h1>{getHomeText("partners_title")}</h1>
                    <StaticText page="home" entry="partners_description"/>
                </div>
                <div className="d-none d-md-block">
                    <PartnerList partners={this.props.partners} numOnPage={12} useDots/>
                </div>
                <div className="d-block d-md-none">
                    <PartnerList partners={this.props.partners} numOnPage={6}/>
                </div>
                <LearnMore href={buildUrl(Route.PLATFORM + "#partners-anchor")} newTab={false}/>
            </BoundSection>
        );
    }
}