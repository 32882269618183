import * as React from "react";
import {ButtonLink, Props as ButtonLinkProps} from "./ButtonLink";
import {getText} from "../../../../utils/static-utils";
import {SharedSC} from "../../../../utils/lang";

type Props = Pick<ButtonLinkProps, "href" | "className" | "newTab" | "useSpan" | "onClick">;

export class ButtonMore extends React.PureComponent<Props & {entry: keyof SharedSC;}> {
    public render() {
        const text = getText("shared", this.props.entry);
        const icon = text.indexOf("+") !== -1 ? "+" : "";
        return (
            <ButtonLink
                {...this.props}
                className={(this.props.className || "") + " button-more"}
                text={text.replace("+", "").trim()}
                icon={icon}
            />
        );
    }
}

export class LearnMore extends React.PureComponent<Props> {
    public render() {
        return (
            <ButtonMore
                {...this.props}
                entry={"button_learn_more"}
            />
        );
    }
}

export class SeeMore extends React.PureComponent<Props> {
    public render() {
        return (
            <ButtonMore
                {...this.props}
                entry={"button_see_more"}
            />
        );
    }
}