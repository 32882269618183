/**
 * Transforms an array of anything to an array containing sub-arrays of maximum the given size.
 * @param array - The array to use.
 * @param chunkSize - The size of the sub-arrays (chunks).
 */
export const arrayChunks = <T>(array: T[], chunkSize: number): T[][] => {
    return array.reduce((acc: T[][], item: T, index: number) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!acc[chunkIndex]) {
            acc[chunkIndex] = []
        }
        acc[chunkIndex].push(item);
        return acc as T[][];
    }, [])
};