import * as React from "react";
import * as sanitizeHtml from "sanitize-html";
import {keepFirstParagraph} from "../../../utils/static-utils";

interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
    text: string;
    maxLength?: number;
    keepFirstParagraph?: boolean;
}

/**
 * A text as injected HTML.
 */
export class Text extends React.PureComponent<Props> {
    public render() {
        const {maxLength, text, keepFirstParagraph: keepFirst, ...props} = this.props;

        let t = text;
        if (maxLength && Number.isInteger(maxLength) && maxLength > 0 && text && text.length > maxLength) {
            t = t.substring(0, maxLength) + "...";
        }
        if (keepFirst) {
            t = keepFirstParagraph(t, false);
        }

        t = sanitizeHtml(t);

        return (
            <div
                {...props}
                className={"text " + (props.className || "")}
                dangerouslySetInnerHTML={{__html: t}}
            />
        );
    }
}
