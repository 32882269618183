import * as React from "react";
import {buildUrl, Route} from "../../utils/route-utils";
import {ChangeLang} from "./ChangeLang";
import {getText} from "../../utils/static-utils";
import {PageType} from "../../utils/directus-types/static-page";
import {StaticImages} from "../utils/static-images";

export interface Props {
    activePage?: PageType;
    scrolled?: boolean; // whether the navbar should be on "scrolled" mode in all cases
}

interface State {
    menuOpen: boolean;
    scrolled: boolean;
}

export class Navbar extends React.Component<Props, State> {
    private readonly mapping = {
        home: {url: buildUrl(Route.HOME), text: getText("shared", "navbar_home_title")},
        platform: {url: buildUrl(Route.PLATFORM), text: getText("shared", "navbar_platform_title")},
        projects: {url: buildUrl(Route.PROJECTS), text: getText("shared", "navbar_projects_title")},
        teaching: {url: buildUrl(Route.TEACHING), text: getText("shared", "navbar_teaching_title")},
        activities: {url: buildUrl(Route.ACTIVITIES), text: getText("shared", "navbar_activities_title")},
    };

    private readonly contact = {
        url: getText("shared", "navbar_contact_link"),
        text: getText("shared", "navbar_contact_title")
    };

    public readonly state: State = {menuOpen: false, scrolled: false};

    public componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        if (window.pageYOffset > 0) {
            this.setState({scrolled: true})
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    public render() {
        const links = (
            <>
                {Object.keys(this.mapping).map(page => (
                    <a
                        key={page}
                        className={"nav-link" + (this.props.activePage === page ? " active" : "") + (page === "home" ? " d-lg-none" : "")}
                        href={this.mapping[page].url}
                    >
                        {this.mapping[page].text}
                    </a>
                ))}
            </>
        );

        return (
            <div className={`navbar ${(this.props.scrolled || this.state.scrolled) ? "navbar-scrolled" : ""}`}>
                <div className="container">
                    <a className="navbar-brand" href={buildUrl(Route.HOME)}>
                        <img className="d-none d-lg-block desktop" src={StaticImages.logos.ingesanNav}/>
                        <img className="d-block d-lg-none mobile" src={StaticImages.logos.ingesanNavMob}/>
                    </a>
                    <div className="links d-none d-lg-flex">
                        {links}
                        <a className="nav-link" href={this.contact.url}>
                            <i className="far fa-envelope"/>
                        </a>
                        <ChangeLang/>
                    </div>
                    <div className="links-md-down d-flex d-lg-none">
                        <div className="burger-icon" onClick={this.handleBurgerClick}>
                            <div/><div/><div/>
                        </div>
                        <div className={"burger-menu bg-blue-orange" + (this.state.menuOpen ? "" : " hidden")}>
                            <div className="container">
                                <div className="header">
                                    <img
                                        className="close-button"
                                        src={StaticImages.ui.closeButton.white}
                                        onClick={this.handleBurgerClick}
                                    />
                                </div>
                                <div className="content">
                                    <div className="links">
                                        {links}
                                        <a className="nav-link" href={this.contact.url}>{this.contact.text}</a>
                                    </div>
                                    <ChangeLang/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleScroll = () => {
        if (window.pageYOffset > 0 && !this.state.scrolled) {
            this.setState({scrolled: true});
        }
        if (window.pageYOffset === 0 && this.state.scrolled) {
            this.setState({scrolled: false});
        }
    };

    private handleBurgerClick = () => this.setState((state) => ({menuOpen: !state.menuOpen}));
}
