import * as React from "react";
import {buildUrl, Route} from "../../utils/route-utils";
import {TextBlock} from "../utils/components/TextBlock";
import {BoundSection} from "./BoundSection";
import {getHomeText} from "../../utils/static-utils";
import {LearnMore} from "../utils/components/buttons/ButtonMore";
import {StaticImages} from "../utils/static-images";

export class Presentation extends React.Component {
    public render() {
        return (
            <BoundSection id="presentation" resume="who">
                <div className="container">
                    <div className="team">
                        <TextBlock
                            className="platform"
                            title={getHomeText("platform_presentation_title")}
                            content={getHomeText("platform_presentation_description")}
                            buttonComponent={<LearnMore href={buildUrl(Route.PLATFORM)} newTab={false}/>}
                        />
                        <div className="schools d-none d-lg-flex">
                            <img className="heig" src={StaticImages.logos.heig}/>
                            <i className="fas fa-chevron-right"/>
                            <img className="ingesan" src={StaticImages.logos.ingesan}/>
                            <i className="fas fa-chevron-left"/>
                            <img className="hesav" src={StaticImages.logos.hesav}/>
                        </div>
                        <div className="schools d-flex d-lg-none">
                            <div className="schools-only">
                                <img className="heig" src={StaticImages.logos.heig}/>
                                <img className="hesav" src={StaticImages.logos.hesav}/>
                            </div>
                            <i className="fas fa-chevron-down"/>
                            <img className="ingesan" src={StaticImages.logos.ingesan}/>
                        </div>
                    </div>
                </div>
            </BoundSection>
        );
    }
}
