import * as React from "react";
import {InstitutionData} from "../../utils/directus-types/institutions";
import {getLogo} from "../../utils/media";
import {getTranslation} from "../../utils/lang";
import {ClickableContent} from "../utils/components/ClickableContent";

interface Props extends InstitutionData {
    className?: string;
}

export class Partner extends React.PureComponent<Props> {
    public render() {
        const {medias, className, acronym, translations} = this.props;
        const translation = getTranslation(translations);
        return (
            <div className={(className || "col-6 col-md-3 col-lg-2") + " partner-wrapper"}>
                <ClickableContent onClick={this.handleClick}>
                    <a
                        className="partner"
                        target="_blank"
                        title={(translation && translation.name) || acronym || ""}
                        draggable={false}
                    >
                        <img src={getLogo(medias)} draggable={false}/>
                    </a>
                </ClickableContent>
            </div>
        );
    }

    public handleClick = () => this.props.link && window.open(this.props.link);
}