/* Global settings */
/**
 * The default language to use in the website.
 */
export const DEFAULT_LANG = "fr";

/* express */
export const EXPRESS_PORT = 3000;
export const SERVER_BASE = "https://www.ingenierie-sante.ch";

/* Directus API base */
export const API_BASE = SERVER_BASE + "/directus2/public/is";
export const API_TABLES = API_BASE + "/items";

// Needs to be included at the end to have all the relations data
export const DIRECTUS_QUERY_SUFFIX = "meta=*&fields=*.*";

/* Servlet API base */
export const API_SERVLET_BASE = SERVER_BASE + "/api";

/* Languages */
export const LANGUAGES_API_BASE = `${API_TABLES}/languages`;

/* Static content */
export const STATIC_CONTENT_API_BASE = `${API_TABLES}/static_page`;

/* Users */
// From Servlet
export const USER_API_BASE = `${API_SERVLET_BASE}/users`;
export const USER_SEARCH_BASE = `${USER_API_BASE}/search`;

/* Projects */
export const PROJECTS_PAGE_SIZE = 15; // How many projects to fetch in a page (in projects page)
export const HOME_PROJECT_NUMBER = 5; // How many projects to display on the home page
export const PROJECTS_API_BASE = `${API_TABLES}/project`;
export const PROJECTS_BASE_QUERY = `${PROJECTS_API_BASE}?${DIRECTUS_QUERY_SUFFIX}&filter[status][eq]=PUBLISHED`;
export const PROJECTS_HOME_QUERY = `${PROJECTS_BASE_QUERY}&limit=${HOME_PROJECT_NUMBER}&filter[featured][eq]=1&sort=-startDate`;

// Projects from Servlet
export const PROJECTS_SEARCH_BASE = `${API_SERVLET_BASE}/projects/search/findAllProjectsWithParams` +
    `?size=${PROJECTS_PAGE_SIZE}&distributions=IS_HEE,IS&sort=startDate,desc`;

/* Teaching */
export const TEACHING_API_BASE = `${API_TABLES}/education`;

/* News and events */
export const ACTIVITY_PAGE_SIZE = 6; // How many activities to fetch in a page
export const ACTIVITY_TO_FETCH = 6; // How many activities to fetch
export const HOME_NEWS_NUMBER = 5; // How many news to fetch in home page
export const HOME_EVENT_NUMBER = 5; // How many events to fetch in home page
export const NEWS_API_BASE = `${API_TABLES}/news`;
export const NEWS_BASE_QUERY = `${NEWS_API_BASE}?${DIRECTUS_QUERY_SUFFIX}&limit=${ACTIVITY_TO_FETCH}&sort=-date&filter[status][eq]=PUBLISHED`;
export const NEWS_HOME_QUERY = `${NEWS_API_BASE}?${DIRECTUS_QUERY_SUFFIX}&limit=${HOME_NEWS_NUMBER}&sort=-date&filter[status][eq]=PUBLISHED`;
export const EVENT_API_BASE = `${API_TABLES}/event`;
export const EVENT_BASE_QUERY = `${EVENT_API_BASE}?${DIRECTUS_QUERY_SUFFIX}&limit=${ACTIVITY_TO_FETCH}&sort=-dateFrom&filter[status][eq]=PUBLISHED`;
export const EVENT_HOME_QUERY = `${EVENT_API_BASE}?${DIRECTUS_QUERY_SUFFIX}&limit=${HOME_EVENT_NUMBER}&sort=-dateFrom&filter[status][eq]=PUBLISHED`;

/* Institutions */
export const INSTITUTIONS_API_BASE = `${API_TABLES}/institution`;
export const INSTITUTIONS_BASE_QUERY = `${INSTITUTIONS_API_BASE}?${DIRECTUS_QUERY_SUFFIX}`;
export const INSTITUTIONS_BASE_QUERY_WITH_ORDER = `${INSTITUTIONS_BASE_QUERY}&sort=-priority&filter[priority][gt]=0`;

/* Skills */
export const SKILLS_API_BASE = `${API_TABLES}/competence`;
export const SKILLS_BASE_QUERY = `${SKILLS_API_BASE}?${DIRECTUS_QUERY_SUFFIX}`;

/* Project institution roles */
export const PIR_BASE_QUERY = `${API_TABLES}/project_institution_role?${DIRECTUS_QUERY_SUFFIX}&filter[id][in]=`;

/* Project user roles */
export const PUR_BASE_QUERY = `${API_TABLES}/project_user_role?${DIRECTUS_QUERY_SUFFIX}&filter[id][in]=`;
