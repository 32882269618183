import * as React from "react";
import {BoundSection} from "./BoundSection";
import {ButtonLink} from "../utils/components/buttons/ButtonLink";
import {buildOverview, buildUrl, Route} from "../../utils/route-utils";
import {Card} from "../utils/components/Card";
import {getHomeText} from "../../utils/static-utils";
import {StaticText} from "../utils/components/StaticText";
import {ActivityData, EventData, NewsData} from "../../utils/directus-types/activities";
import {getBanner} from "../../utils/media";
import {getTranslation} from "../../utils/lang";
import {sanitizeDate} from "../../utils/date-utils";
import {SwipeList} from "../utils/components/lists/SwipeList";

interface Props {
    news: NewsData[];
    events: EventData[];
}

interface State {
    focusedIndex: number;
}

type ActivityType = keyof Props;
type ActivityWithType = {type: ActivityType, data: ActivityData};

export class Activities extends React.Component<Props, State> {
    public readonly state: State = {focusedIndex: 0};
    private activities: ActivityWithType[] = [];

    private xsList: SwipeList; // The ref of the list used in XS format.
    private smList: SwipeList; // The ref of the list used in SM format and above.

    private readonly initIndex: number; // The initial index, determined by which activity is the next to follow.

    public static defaultProps: Partial<Props> = {
        news: [],
        events: [],
    };

    public constructor(props: Props) {
        super(props);

        const getTime = (v: string) => (new Date(sanitizeDate(v || ""))).getTime();

        // Create a timestamp for each element and add the activity type
        const timestamped: ({time: number, data: ActivityWithType})[] = [];
        this.props.news.forEach(el => timestamped.push({time: getTime(el.date), data: {type: "news", data: el as ActivityData}}));
        this.props.events.forEach(el => timestamped.push({time: getTime(el.dateFrom), data: {type: "events", data: el as ActivityData}}));

        // Sort the activities by date, newer first
        timestamped.sort((a, b) => b.time - a.time);
        this.activities = timestamped.map(el => el.data);
        this.initIndex = timestamped.filter(el => el.time > new Date().getTime()).length - 1;
    }

    public componentDidMount() {
        // Changes the focus index accordingly to the next activity to come.
        this.setState({focusedIndex: this.initIndex}, () => {
            this.xsList.setPage(this.initIndex);
            this.smList.setPage(this.initIndex);
        })
    }

    public render() {
        return (
            <BoundSection id="activities" resume="when" className="bg-blue-orange">
                <div className="container">
                    <div className="header">
                        <h1>{getHomeText("activities_title")}</h1>
                        <StaticText page={"home"} entry={"activities_description"}/>
                    </div>
                    <div className="d-none d-sm-block">
                        <SwipeList onRef={this.handleSMRef} pageWidth={320} onPageChange={this.handlePageChange}>
                            {this.activities.map(this.renderCard)}
                        </SwipeList>
                    </div>
                    <div className="d-block d-sm-none">
                        <SwipeList onRef={this.handleXSRef}>
                            {this.activities.map(this.renderCard)}
                        </SwipeList>
                    </div>
                    <ButtonLink
                        className={"all white"}
                        text={getHomeText("button_all_activities")}
                        href={buildUrl(Route.ACTIVITIES)} newTab={false}
                    />
                </div>
            </BoundSection>
        );
    }

    private renderCard = (activity: ActivityWithType, index: number) => {
        if (!activity) {
            return null;
        }

        const translation = getTranslation(activity.data.translations);

        if (!translation) {
            return null;
        }

        const fIndex = this.state.focusedIndex;
        const fClass = {
            [fIndex - 1]: "focused-before",
            [fIndex]: "focused",
            [fIndex + 1]: "focused-after",
        };

        const link = buildUrl(`/${activity.type}/${activity.data.id}/${buildOverview(translation.title)}`);
        const defaultBanner = Route.ASSETS + "/images/placeholders/" + (activity.type === "events" ? "event.jpeg" : "news.jpeg");

        return (
            <div key={index} className={"card-wrapper " + (fClass[index] || "unfocused")}>
                <Card
                    image={getBanner(activity.data.medias) || defaultBanner}
                    description={translation.description}
                    title={translation.title}
                    date={{
                        value: activity.type === "events" ? (activity.data as EventData).dateFrom : (activity.data as NewsData).date,
                        bubble: index % 2 === 0 ? "blue" : "orange"
                    }}
                    link={link}
                    onClick={this.handleCardClick(link, index)}
                />
            </div>
        );
    };

    private handleXSRef = (ref: SwipeList) => this.xsList = ref;
    private handleSMRef = (ref: SwipeList) => this.smList = ref;

    private handlePageChange = (index: number) => this.setState({focusedIndex: index});

    private handleCardClick = (url, index) => () => {
        if (index === this.state.focusedIndex) {
            url && window.open(url);
        } else {
            this.xsList.setPage(index);
            this.smList.setPage(index);
        }
    }
}
