import * as sanitizeHtml from "sanitize-html";
import {ActivitiesSC, HomeSC, Lang, LangSC, PlatformSC, ProjectsSC, SharedSC, TeachingSC} from "./lang";
import {SCTranslation} from "./directus-types/static-page";

/**
 * Gets the plain / rich text in the entry for a given page.
 * If the entry doesn't exists, returns an empty string.
 *
 * @param pKey - The key of the page to use (for example "home").
 * @param eKey - The key of the entry to use (for example "page_title"). Must be a key existing in the page object.
 */
export const getText = <P extends keyof LangSC>(pKey: P , eKey: keyof LangSC[P]) => {
    if (!pKey || !eKey) {
        return "";
    }

    const page = Lang.sc[pKey];
    if (!page) {
        return "";
    }

    const translation: SCTranslation = page[eKey as string];
    if (!translation) {
        return "";
    }

    const field: keyof Pick<SCTranslation, "plaintext" | "richtext"> = translation.isRich ? "richtext" : "plaintext";

    return translation[field] || "";
};

// Shorthands to easier use of getText for a given page
export const getHomeText = (key: keyof HomeSC) => getText("home", key);
export const getPlatText = (key: keyof PlatformSC) => getText("platform", key);
export const getProjText = (key: keyof ProjectsSC) => getText("projects", key);
export const getTeachText = (key: keyof TeachingSC) => getText("teaching", key);
export const getActText = (key: keyof ActivitiesSC) => getText("activities", key);
export const getSharedText = (key: keyof SharedSC) => getText("shared", key);

/**
 * Keeps the first paragraph of the given html.
 * @param html - The html to use.
 * @param sanitize - Whether to sanitize the output, closing unclosed tags and removing trailing nonsensical entities. Default true.
 */
export const keepFirstParagraph = (html: string, sanitize = true) => {
    const firstP = html?.split("</p>")?.[0]?.split("<br />")?.[0] || "";
    return sanitize ? sanitizeHtml(firstP) : firstP;
};

/**
 * Completely removes the tags from a string containing html.
 * @param html - The html to use.
 */
export const removeHTMLTags = (html: string) => sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: []
});
